const zhCN = {
    title: "邮件登入",
    account: "账号",
    plz_account: "请输入您的账号",
    pwd: "密码",
    plz_pwd: "请输入您的登录密码",
    login: "登录",
    login_failed: "登录失败",
    plz_ga: "请输入双重认证码",
    ga: "双重认证码（如有）",
    ga_error:"双重认证码错误",
    forget: "忘记密码？",
    find: "请至APP找回",
    admin: "商户中心",
    out: "退出",
    list1Title: "邀请好友",
    list1Detail: "邀请好友信息列表",
    list2Title: "代理信息",
    list2Detail: "代理的信息列表",
    list3Title: "挂单记录",
    list3Detail: "我的挂单",
    list4Title: "接口设置",
    list4Detail: "设置白名单及密钥",
    list5Title: "后台登入白名单",
    total: "总人数",
    num: "余额",
    invite_id: "推荐者ID",
    type: "类型",
    in_time: "加入时间",
    No: "编号",
    state:  "状态",
    edit: "编辑",
    buy: "回购",
    sell: "出售",
    online: "在线",
    del: "已删除",
    del_btn: "删除",
    level: "等级",
    directNum: "直推人数",
    directMoney: "直推账单额",
    proxy_total: "下线人数",
    proxy_total_money: "总账单额(V币)",
    agent: "商户",
    boss: "金主",
    player: "玩家",
    welcome_message: "欢迎你",
    token_input: "商戶密钥",
    token_placeholder: "按获取取得商戶密钥",
    name_input: "商戶名称",
    name_placeholder: "输入名称",
    whitelist_input: "IP 白名单",
    whitelist_placeholder: "IP 白名单",
    dashboard:"仪表盘",
    changeavatar:"更换头像",
    loginname:"登录名",
    accountoverview:"账户总概",
    walletaddress:"钱包地址",
    numberofrechargeusers:"充值用户人数",
    numberofusersissued:"下发用户人数",
    user:"用户",
    username:"用户名",
    nickname:"昵称",
    realname:"真实姓名",
    address:"地址",
    payeeaddress: "居住地区",
	plzAddress: "请输入您的居住地区",
    accountwrongtype:"账号格式错误",
    orgname: "所属银行",
    plzorgname: "请输入您的银行名称",
    qr: "收款码",
    addQr: "添加收款码",
	plzQr: "请上传支付二维码",
    nobind: "未绑定",
    bind: "已绑定",
    approving:"审批中",
    reject:"已拒绝",
    wallet:"钱包",
    balance:"余额",
    secondary:"次级",
    available:"可用",
    frozen:"冻结",
    amount:"金额",
    myrate:"我的费率",
    issuance:"下发",
    deposit:"充值",
    transfer:"转账",
    withdrawal:"提现",
    transactions:"笔",
    quantity:"数量",
    playerrecharge:"玩家充值(总额/手续)",
    playerwithdrawals:"玩家提现(总额/手续)",
    merchantcashwithdrawal:"商户提现(总额/手续)",
    merchanttransfer:"商户转账(总额/手续)",
    totaldepositdiscounttoday:"今日充值优惠总量",
    totalwithdrawdiscounttoday:"今日提现优惠总量",
    totalamountcollectedtoday:"今日收款总量",
    todaytotalwithdrawalamount:"今日出款总量",
    todayrechargeamount:"今日充值量",
    volumeissuedtoday:"今日下发量",
    balanceinpendingorder:"挂单中余额",
    transactionamount:"交易中金额",
    withdrawrecord:"提现记录",
    userbinding:"用户绑定",
    walletflow:"钱包流水",
    pendingordermanagement:"挂单管理",
    pendingorder:"我的挂单",
    neworder:"新挂单",
    buyorder:"挂单买分",
    sellorder:"挂单卖分",
    buyvtoken:"购买数量 (V币)",
    sellvtoken:"出售数量 (V币)",
    buytokenplaceholder:"请输入购买数量，单笔限额不可低于10",
    selltokenplaceholder:"请输入出售数量，单笔限额不可低于10",
    buytokenoption:"选择付款方式 (可多选）",
    selltokenoption:"选择收款方式 (可多选）",
    upperlowerlimit:"上下降比例",
    priceTitle:"",
    downPriceTitle:"降价",
	upPriceTitle:"加价",
    tasklistingsuccess:"挂单成功",
    payment_not_set:"未设置支付方式",
    bind_new_payment:"绑定支付方式",
    confirm_set:"进行绑定",
    cancel_set:"取消",
    can_payment:"可用支付方式",
    split_task:"是否拆单",
    split_task_yes:"是",
    split_task_no:"否",
    mintrade:"最小交易量",
    unit:"(V币)",
    oncemin: "一次性交易的最低交易量",
    firstset: "您未设置支付方式！",
    HighFeeTitle:"确认手续费",
    highFeeContent:"此笔交易包含非可售金额，需收取",
    highFeeContentAfter:"手续费，确认继续？",
    FeeTitle:"确认手续费",
    FeeContent:"此交易将收取",
    FeeContentAfter:"手续费，确认继续？",
    FeeConfirm:"确认",
    maxlimit:"金额超过可用余额",
	minlimit:"金额低于最少额度",
    mintradeincorrect: "最小交易量不正确",
	maxtradeincorrect: "最大收购量不正确",
    pwdIsEmpty:"交易密码不能为空",
    selectPaymentToReceiveMethod:"请选择支付方式",
    availablesellnum: "可售数量",
	noavailablesellnum: "次级可售数量",
    buylimitationlower:"买单金额低于下限，请联系客服",
	buylimitationupper:"买单金额高于上限，请联系客服",
    myinvitation:"我的邀请",
    setting:"设置",
    settingsuccess:"设置成功",
    operation:"操作",
    paytype:"收付款方式",
    whitelistsettings:"接口设置",
    webwhitelistsettings:"后台登入白名单",
    resetpassword:"修改登录密码",
    resetsubpassword:"修改支付密码",
    subpassword:"支付密码",
    subpasswordplaceholder:"请输入支付密码",
    gapassword:"动态验证码",
    gapassword_ifany:"动态验证码(如有)",
    signout:"登出",
    manualissuanceofapplication:"人工下发申请",
    merchanttransfer:"商户互转",
    memberaddress:"会员地址",
    recipientaddress:"收款方地址",
    inputwalletaddress:"输入对方钱包地址",
    quantityissued:"下发数量",
    quantitytransfer:"转账数量",
    quantitywithdraw:"提现数量",
    cashwithdraw:"提现金额",
    fee:"手续费",
    submit:"提交",
    bindNow: "立即绑定",
	unbindNow: "解除绑定",
    reset:"重置",
    inquiry:"查询",
    transfersuccess:"转账成功",
    gapasswordincorrect:"动态验证码不正确",
    subpasswordincorrect:"支付密码不正确",
    transferfailed:"转账失败，请重试",
    withdrawfailed:"提现失败，请重试",
    withdrawsuccess:"提现成功",
    pleaseinputwalletaddress:"请输入钱包地址",
    pleaseinputtransferamount:"请输入转账数量",
    pleaseinputsubpassword:"请输入支付密码",
    pleaseinputgapassword:"请输入动态验证码",
    pleaseinputbranchname:"请输入支行名称",
    pleaseinputname:"请输入姓名",
    pleaseinputaccountnumber:"请输入账号",
    pleaseinputoldpassword:"请输入旧密码",
    pleaseinputnewpassword:"请输入新密码",
    pleaseconfirmnewpassword:"请确认新密码",
    pleaseinputoldsubpassword:"请输入旧支付密码",
    pleaseinputnewsubpassword:"请输入新支付密码",
    pleaseconfirmnewsubpassword:"请确认新支付密码",
    rmb:"人民币",
    type:"类型",
    todayexchangerate:"今日汇率",
    amountcanwithdraw:"可提额度",
    withdrawall:"全部提现",
    branchname:"支行名称",
    name:"姓名",
    plzname: "请输入您的姓名",
    plzChinese:"姓名仅限填写中文",
    plzQc: "请上传支付二维码",
    accountnumber:"账号",
    remark:"备注",
    plzremark:"请输入收款码备注",
    plzaccount:"请输入您的账号",
    minwithdraw:"单笔下限",
    withdrawordernumber:"提现单号",
    withdrawcurrency:"提现币种",
    withdrawaddress:"提现地址",
    approvalstatus:"审核状态",
    underreview:"审核中",
    timequery:"时间查询",
    to:"至",
    total:"合计",
    downloadlist:"下载列表",
    createtime:"创建时间",
    updatetime:"更新时间",
    currencynumber:"币种数量",
    withdrawhash:"提现哈希",
    everypageshow:"每页显示",
    remarks:"备注",
    numberofcoins:"币数量",
    realnumberofcoins:"实收币数量",
    totalbalance:"总余额",
    merchanttransferto:"商户转账入",
    merchanttransferout:"商户转账出",
    systemreturns:"系统退回",
    merchantreceivesrecharge:"商户收到充值",
    merchantwithdrawscurrency:"商户出币提现",
    administratortransfersin:"管理员转账入",
    administratortransfersout:"管理员转账出",
    ordernumber:"订单号",
    orderstatus:"订单状态",
    callback:"回调",
    depositor:"充值人昵称",
    depositorwalletaddress:"充值人钱包地址",
    issuer:"下发人昵称",
    issuerwalletaddress:"下发人钱包地址",
    inprogress:"进行中",
    coinstransfered:"已转币",
    expired:"逾时",
    callbackfailed:"回调失败",
    callbackrequestsubmitted:"已经发出回调请求",
    confirm:"确认",
    cancel:"取消",
    confirmorderdelection:"确认删除订单",
    obtain:"获取",
    oldpassword:"旧密码",
    newpassword:"新密码",
    confirmnewpassword:"确认新密码",
    newpasswordincorrectformat:"新密码格式不正确",
    newsubpasswordincorrectformat:"新支付密码格式不正确",
    oldpasswordnotmatchnewpassword:"新密碼與确认密碼不相符",
    oldsubpasswordnotmatchnewsubpassword:"新支付密码与确认支付密码不相符",
    resetpasswordsuccess:"重置密码成功",
    resetsubpasswordsuccess:"重置支付密码成功",
    resetpasswordfailed:"重置密码失败，请重试",
    oldsubpassword:"旧支付密码",
    newsubpassword:"新支付密码",
    confirmnewsubpassword:"确认新支付密码",
    settingupdatesuccess:"设置成功",
    settingupdatefail:"设置失败",
    app_name_exists:"商戶名称已存在",
    notinwhitelist:"不在白名单禁止使用。",
    whitelist_hints:"IP白名单，多个IP请用英文逗号隔开",
    ga_verification:"谷歌验证",
    gaTitle:"动态验证码",
    input_instruction:"1.扫码或手动输入验证码:",
    input_instruction_2:"2.输入动态验证器上的6位验证",
    bind_now:"立即绑定",
    input_6_digit_code:"请填写6位验证码",
    already_bind:"你已绑定动态验证码。如有任何问题，请联系客服。",
    ga_verification_failed:"动态验证码验证失败",
    depositrebate:"下线充币回佣",
    buyorderrebate:"下线买币回佣",
    takerdeductusdt:"抢单卖出",
    makerdeductusdt:"挂单购买",
    merchantmarkupdeduct:"抢单购买補貼",
    deposit_offer_percent:"每日充值优惠",
    deposit_offer_dla:"每日充值优惠上限",
    deposit_offer_dln:"每日充值次数上限",
    withdraw_offer_percent:"每日提现优惠",
    withdraw_offer_dla:"每日提现优惠上限",
    withdraw_offer_dln:"每日提现次数上限",
    pls_deposit_offer_percent:"请输入优惠比例",
    pls_deposit_offer_dla:"请输入充值上限",
    pls_deposit_offer_dln:"请输入充值优惠比例",
    pls_withdraw_offer_percent:"请输入优惠比例",
    pls_withdraw_offer_dla:"请输入提现每日优惠上限",
    pls_withdraw_offer_dln:"请输入提现每日次数上限",
    withdraw_deposit_offer_success:"充提设置成功",
    withdraw_deposit_offer_fail:"充提设置失败",
    discount:"优惠",
    offerAdd:"优惠加款",
    offerDeduct:"优惠扣款",
    withdraw_deposit_offer:"充提优惠",
    withdraw_deposit_settings:"充提设置",
    deposit_offer_percent_range:"超出优惠比例范围",
    popupContentExistingTask:"此支付方式正有订单使用，请完成订单后再解除绑定。",
    deposit_min:'用户充值最低额度',
    withdraw_min:'用户提现最低额度',
    depositOfferAdd:"充值优惠加款",
    depositOfferDeduct:"充值优惠扣款",
    withdrawOfferAdd:"提现优惠加款",
    withdrawOfferDeduct:"提现优惠扣款",
    transactiontype:"流水类型",
}

export default zhCN;
