import { useCallback, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useMemo } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import http from '../lib/http'

import Header from "../components/header"
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from 'react-i18next'
import Store from 'store'

import "react-datepicker/dist/react-datepicker.css";
import "../App.scss"
import { zhCN } from 'date-fns/locale';

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';

registerLocale('zhCN', zhCN)

function createData(id,amount,total_amount,fee,subject,time) {
    return { id, amount, total_amount, fee, subject,time };
}


// Define your styles using the makeStyles hook
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      padding: '20px',
      backgroundColor: '#f5f5f5',
    },
    title: {
      fontSize: '20px',
      fontWeight: 'bold',
    },
    backdrop: {
        zIndex: 10000,
        color: '#fff',
        backgroundColor: 'rgba(0,0,0,0.7)',
      },
}));

const FundFlow = () => {
    const { t } = useTranslation();

    const url=`${process.env.REACT_APP_API_URL}/agentbackend/user/agent/fundflow-list`;
    const callback_url=`${process.env.REACT_APP_API_URL}/agentbackend/user/agent/callback`;
    const [data,setData] = useState([]);
    const { search } = useLocation();
    const [direction,setDirection] = useState('');

    const [startDate, setStartDate] = useState(); 
    const [endDate, setEndDate] = useState(); 
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalFee, setTotalFee] = useState(0);

    const [order_query, setOrderQuery] = useState('');
    const [address_query, setAddressQuery] = useState('');
    const [username_query, setUsernameQuery] = useState('');
    const [params, setParams] = useState({});
    const [progress, setProgress] = useState(10);
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    useMemo(() => getDirection(search), [search]);
    useEffect(async() => {
              
    },[]);

    async function updateTableDate(_direction,_page,_rowsPerPage){
        var params = {
            "order_no":order_query,
            "wallet_address":address_query,
            "name":username_query,
            "direction":_direction,
            "startDate":startDate,
            "endDate":endDate,
            "page":parseInt(_page)+1,
            "limit":_rowsPerPage
        };
        if(params['startDate'] != null){
            if(params['startDate'].getHours() == 0 && params['startDate'].getMinutes() == 0 && params['startDate'].getSeconds() == 0){
                params['startDate'] = new Date(params['startDate'].setHours(0,0,0,0));
            }
            params['startDate'] = new Date(params['startDate'].getTime() + (8*60*60*1000));
        }
        if(params['endDate'] != null){
            if(params['endDate'].getHours() == 0 && params['endDate'].getMinutes() == 0 && params['startDate'].getSeconds() == 0){
                params['endDate'] = new Date(params['endDate'].setHours(23,59,59,0));
            }
            params['endDate'] = new Date(params['endDate'].getTime() + (8*60*60*1000));
        }
       
        setParams(params);
        let data = await http({ url: url, method: 'post',params:params}).then(response=>{
            // updateTableDate(response.data.lists.data);
            if(response.errorCode!==undefined){
                alert(t('notinwhitelist'));
                Store.remove('user')
                window.location.href = '/'
            }
            if(response.data.lists!=0){
                const temp_row = response.data.lists.data;
                console.log(temp_row);
                setRows(temp_row);
                setTotalRowCount(response.data.lists.total_rows_count);
                setTotalAmount(response.data.amount);
                setTotalFee(response.data.fee);
            }
        });  
    }

    function getDirection(_search){
        const p = new URLSearchParams(_search);
        setDirection(p.get('direction'));
        updateTableDate(p.get('direction'),page,rowsPerPage);
    }

    // const updateTableDate = (_data) => {
    //     const temp_row = _data;
    //     setRows(temp_row);
    // }

    const handleChangePage = (event, newPage) => {
        console.log('newpage',newPage);
        setPage(newPage);
        updateTableDate(direction,newPage,rowsPerPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        console.log('handleChangeRowsPerPage',event.target.value)
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
        updateTableDate(direction,0,event.target.value);
      };

    function timeZoneConvert(date){
        var d = new Date(date);
        return d.toLocaleString('zh-CN', { timeZone: 'Asia/Hong_Kong', hour12: false })
    }

    function statusMassage(status){
        switch(status){
            case "pending":
                return t('inprogress');
            case "authorized":
                return t('coinstransfered');
            case "expired":
                return t('expired');
            case "authorized_no_callback":
                return t('callbackfailed');
        }

    }

    async function goCallback(callback,amount,order_no,remark){
        let data = await http({ url: callback_url, method: 'post',params:{"callback":callback,"amount":amount,"order_no":order_no,"remark":remark}}).then(response=>{
            if(response.message=='ok')
                alert(t('callbackrequestsubmitted'));
        });
    }

    function LinearProgressWithLabel(props) {
        return (
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="white">{`${Math.round(
                props.value,
              )}%`}</Typography>
            </Box>
          </Box>
        );
      }
      
      LinearProgressWithLabel.propTypes = {
        /**
         * The value of the progress indicator for the determinate and buffer variants.
         * Value between 0 and 100.
         */
        value: PropTypes.number.isRequired,
      };

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function saveDataInCSV(fileName = 'export.csv'){
        handleToggle();
        let exportParams = params
        let temp_row=[];
        let exportRows = [];
        exportParams['limit'] = 500
        exportParams['page'] = 1
        
        //Need to get all data and then export, the data retrieved should be increased by 10 each time
        while(exportParams['limit']*(exportParams['page']-1) < totalRowCount){
            setProgress(exportParams['page']/Math.ceil(totalRowCount/exportParams['limit'])*100);
            await http({ url: url, method: 'post',params:exportParams}).then(response=>{
                if(response.data.lists!=0){
                    temp_row = response.data.lists.data;
                    exportRows = exportRows.concat(temp_row);
                }
            });
            //exportParams['limit'] += 100
            exportParams['page'] += 1
            //set time out to avoid too many requests
            await delay(1000);
        }
        console.log("Final ExportRows",exportRows);

        const data = JSON.parse(JSON.stringify(exportRows));
        if (data.length === 0) {
          console.warn('Data is empty.');
          return;
        }

        data.map((item) => {
            console.log(item);
            item[t('createtime')] = timeZoneConvert(item['created_at'])  ;
            delete item['created_at'] ;

            item[t('ordernumber')] = item['auth']['order_no']  ;

            item[t('username')] = direction == "input"?item['fromuser']['name']:item['touser']['name'] ;
            item[t('realname')] = direction == "input"?(item['fromuser']['finished_realname_verify']?item['fromuser']['finished_realname_verify']['id_name']:""):(item['touser']['finished_realname_verify']?item['touser']['finished_realname_verify']['id_name']:"")  ;
            delete item['user'];

            if(direction == "input"){
                item[t('depositor')] = direction == "input"?item['fromuser']['nickname']:item['touser']['nickname']  ;
                item[t('depositorwalletaddress')] = direction == "input"?item['fromuser']['wallet_address']:item['touser']['wallet_address']  ;
            }

            if(direction == "output"){
                item[t('issuer')] = direction == "input"?item['fromuser']['nickname']:item['touser']['nickname']  ;
                item[t('issuerwalletaddress')] = direction == "input"?item['fromuser']['wallet_address']:item['touser']['wallet_address']  ;
            }
            
            item["V币"+t('quantity')] = item['amount']  ;
            delete item['amount'] ;

            item[t('fee')] = item['fee']  ;
            delete item['fee'] ;

            item[t('orderstatus')] = statusMassage(item['auth']['status'])  ;
            delete item['auth'] ;

            delete item['fromuser'] ;
            delete item['touser'] ;
            delete item['updated_at'];
            delete item['third_party_auth_id'];
            delete item['user_id'];
            delete item['sn'];
            delete item['mid'];
            delete item['from_otc_uid'];
            delete item['to_otc_uid'];
        });

      
        const propertyNames = Object.keys(data[0]);
        const rowWithPropertyNames = propertyNames.join(',') + '\n';
      
        let csvContent = rowWithPropertyNames;
      
        const r = [];
      
        data.forEach((item) => {
          const values = [];
      
          propertyNames.forEach((key) => {
            let val = item[key];
      
            if (val !== undefined && val !== null) {
              val = new String(val);
            } else {
              val = '';
            }
            values.push(escapeCsvValue(val));
          });
      
          r.push(values.join(','));
        });
      
        csvContent += r.join('\n');
      
        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      
        // Create a link element and trigger a download
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
      
          // Append the link to the body
          document.body.appendChild(link);
      
          // Trigger the download
          link.click();
      
          // Clean up
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
          handleClose();
        } else {
          console.error('Download is not supported in this browser.');
        }

        console.log(rows);
        console.log(data);
      }
    
      function escapeCsvValue(value){
        // If the value contains a comma, newline, or double quote, wrap it in double quotes
        if (value.includes(',') || value.includes('\n') || value.includes('"')) {
          return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
      }

    return (
        <div className="d-flex">
            <Header></Header>
            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                <Box sx={{ width: '50%' }}>
                    努力加载中...
                    <LinearProgressWithLabel value={progress} />
                </Box>
            </Backdrop>
            <div className="w-100 p-4 d-flex flex-column gap-4 vh-100">
                {direction == 'input' &&
                    <h4 className="text-dark-blue">{t('player')}{t('deposit')}</h4>
                }
                {direction == 'output' &&
                    <h4 className="text-dark-blue">{t('player')}{t('withdrawal')}</h4>
                }
                <div className='w-100 d-flex justify-content-between gap-2 flex-column flex-xl-row'>
                    <div style={{minWidth:300}} className="d-flex gap-2 align-items-center w-100">
                        <span style={{minWidth:70}}>{t('ordernumber')}</span>
                        <input className="w-100" value={order_query} name="order_query" placeholder={t('ordernumber')} onChange={(e)=>setOrderQuery(e.target.value)}/>
                    </div>
                    <div style={{minWidth:300}} className="d-flex gap-2 align-items-center w-100">
                        <span style={{minWidth:70}}>{t('walletaddress')}</span>
                        <input className="w-100" value={address_query} name="address_query" placeholder={t('walletaddress')} onChange={(e)=>setAddressQuery(e.target.value)}/>
                    </div>
                    <div style={{minWidth:300}} className="d-flex gap-2 align-items-center w-100">
                        <span style={{minWidth:70}}>{t('username')}</span>
                        <input className="w-100" value={username_query} name="username_query" placeholder={t('username')} onChange={(e)=>setUsernameQuery(e.target.value)}/>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="d-flex gap-2 align-items-center">
                        <span style={{minWidth:70}}>{t('timequery')}</span>
                        <div style={{width:200}}>
                            <DatePicker locale="zhCN" className="w-200" selected={startDate} onChange={(date) => setStartDate(date)}  timeInputLabel="时间:" dateFormat="yyyy/MM/dd HH:mm:ss" showTimeInput />
                        </div>
                        <span>{t('to')}</span>
                        <div style={{width:200}}>
                            <DatePicker locale="zhCN" className="w-200" selected={endDate} onChange={(date) => setEndDate(date)}   timeInputLabel="时间:" dateFormat="yyyy/MM/dd HH:mm:ss" showTimeInput/>
                        </div>
                        <button className="bg-dark-blue text-white px-3 rounded-2 border-0 fs-6" onClick={()=>updateTableDate(direction,page,rowsPerPage)}>{t('inquiry')}</button>
                    </div>
                    <div className='d-flex gap-2 align-items-center border border-2 border-secondary p-2' role="button" onClick={()=>saveDataInCSV()}>
                        <i className="fa-solid fa-download"></i>
                        <span>{t('downloadlist')}</span>
                    </div>
                </div>
                
                <div className="h-100 overflow-auto">
                    <TableContainer component={Paper}>
                        <Table className="w-100" aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="right">{t('createtime')}</TableCell>
                                <TableCell align="right">{t('ordernumber')}</TableCell>
                                <TableCell align="right">{t('username')}</TableCell>
                                <TableCell align="right">{t('realname')}</TableCell>
                                <TableCell align="right">{t('walletaddress')}</TableCell>
                                {/*<TableCell align="right">{direction == "input"?t('depositor'):t('issuer')}</TableCell>
                                <TableCell align="right">{direction == "input"?t('depositorwalletaddress'):t('issuerwalletaddress')}</TableCell>*/}
                                <TableCell align="right">V币{t('quantity')}</TableCell>
                                <TableCell align="right">{t('fee')}</TableCell>
                                <TableCell align="right">{t('orderstatus')}</TableCell>
                                {direction=='input' 
                                ?<TableCell align="right">{t('operation')}</TableCell>
                                :<TableCell align="right"></TableCell>}
                                
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell align="right">{timeZoneConvert(row.created_at)}</TableCell>
                                <TableCell align="right">{row.auth.order_no}</TableCell>
                                <TableCell align="right">{direction=='input'?row.fromuser.name:row.touser.name}</TableCell>
                                <TableCell align="right">{direction=='input'?(row.fromuser.finished_realname_verify?row.fromuser.finished_realname_verify.id_name:""):(row.touser.finished_realname_verify?row.touser.finished_realname_verify.id_name:"")}</TableCell>
                                <TableCell align="right">{direction=='input'?row.fromuser.wallet_address:row.touser.wallet_address}</TableCell>
                                {/* <TableCell align="right">{direction == "input"?row.user.nickname:row.touser.nickname}</TableCell>
                                <TableCell align="right">{direction == "input"?row.user.wallet_address:row.touser.wallet_address}</TableCell>*/}
                                <TableCell align="right">{parseFloat(row.original_amount).toFixed(2)}</TableCell>
                                <TableCell align="right">{parseFloat(row.fee).toFixed(2)}</TableCell>
                                <TableCell align="right">{statusMassage(row.auth.status)}</TableCell>
                                {direction=='input' 
                                ?<TableCell align="right">
                                    <button className="bg-dark-blue text-white px-3 rounded-2 border-0 fs-6" onClick={()=>{goCallback(row.auth.callback,row.amount,row.auth.order_no,row.auth.remark)}}>{t('callback')}</button>
                                </TableCell>
                                :<TableCell align="right"></TableCell>}
                                </TableRow>
                            ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow className='border border-top'>
                                    <TableCell component="th" scope="row">{t('total')}</TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                    {/* <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>*/}
                                    <TableCell align="right">{totalAmount}</TableCell>
                                    <TableCell align="right">{totalFee}</TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    colSpan={9}
                                    count={totalRowCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    labelRowsPerPage={t('everypageshow')}
                                    SelectProps={{
                                        inputProps: {
                                        'aria-label': 'rows per page',
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                                </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    )
}

export default FundFlow